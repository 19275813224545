<template>
  <v-container fluid>
    <v-form ref="form" v-model="valid" class="mt-5" @submit.prevent="$emit('submit')">
      <v-row>
        <v-col class="pt-1" cols="12" md="4" sm="6">
          <KTextField v-model="values.name" :field="$t('dashboard.fields.dashboardName')" autofocus required/>
        </v-col>
        <v-col cols="12">
          <draggable v-model="values.content" :draggable="false" class="row sortable-list justify-start" tag="div">
            <dashboard-set-column v-for="(col, colIndex) in values.content"
                                  :key="`col.${colIndex}`"
                                  :col-index="colIndex"
                                  @click:addCategory="addCategory">
              <draggable v-model="col.categories"
                         :group="{name:'dashboard'}"
                         :style="isCategoryDragging && {backgroundColor: 'rgba(0,0,0,0.25)'}"
                         class="py-1 fill-height"
                         tag="div"
                         @end="isCategoryDragging = false"
                         @start="isCategoryDragging = true">
                <dashboard-set-category v-for="(category, categoryIndex) in col.categories"
                                        :key="`category.${categoryIndex}`"
                                        :category="category"
                                        :category-index="categoryIndex"
                                        :col-index="colIndex"
                                        style="cursor: move"
                                        @click:addLink="addLink"
                                        @click:editCategory="openCategoryDialog"
                                        @click:deleteCategory="deleteCategory">
                  <draggable v-model="category.links"
                             :group="{name:'links'}"
                             :style="isLinkDragging && {backgroundColor: 'rgba(0,0,0,0.25)'}"
                             class="pb-8"
                             tag="div"
                             @end="isLinkDragging = false"
                             @start="isLinkDragging = true">
                    <dashboard-set-link v-for="(link, linkIndex) in category.links"
                                        :key="`link.${linkIndex}`"
                                        :category-index="categoryIndex"
                                        :col-index="colIndex"
                                        :link="link"
                                        :link-index="linkIndex"
                                        style="cursor: move"
                                        @click:editLink="openLinkDialog"
                                        @click:deleteLink="deleteLink">
                    </dashboard-set-link>
                  </draggable>
                </dashboard-set-category>
              </draggable>
            </dashboard-set-column>
          </draggable>

          <v-dialog :value="!!dialog" width="500" @input="dialog = null">
            <k-field-group language-prefix="dashboard.fields">
              <v-card v-if="dialog === CATEGORY">
                <v-form ref="categoryForm"
                        v-model="categoryValidation"
                        @submit.prevent="updateCategoryDialog({categoryEditingObj, categoryTitle})">
                  <v-card-title class="justify-space-between">
                <span>
                  {{
                    $t("dashboard.fields.category", {
                      type: column ? $t("dashboard.actions.add") : $t("dashboard.actions.edit"),
                    })
                  }}
                </span>
                    <v-btn icon @click="dialog = false">
                      <v-icon>$close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <KTextField v-model="categoryTitle" autofocus field="categoryTitle" required/>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text type="submit">
                      {{ column ? $t("dashboard.actions.add") : $t("dashboard.actions.edit") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
              <v-card v-else-if="dialog === LINK">
                <v-form ref="linkForm"
                        v-model="linkValidation"
                        @submit.prevent="updateLinkDialog({linkEditingObj, linkName, linkUrls, type, identifier, linkOpenInNewWindow})">
                  <v-card-title class="justify-space-between">
                <span>
                  {{
                    $t("dashboard.fields.link", {
                      type: category ? $t("dashboard.actions.add") : $t("dashboard.actions.edit"),
                    })
                  }}
                </span>
                    <v-btn icon @click="dialog = false">
                      <v-icon>$close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <KTextField v-model="linkName" autofocus field="linkName" required/>
                    <KSelect v-model="type"
                             :items="[{text: 'URL', value: 'url'},{text: 'Portal', value: 'dashboard'}]"
                             field="type"
                             required/>
                    <template v-if="type === 'url'">
                      <KTextField v-model="linkUrls[index]"
                                  field="linkUrl"
                                  :required="index === 0"
                                  :key="index"
                                  v-for="(url, index) in linkUrls"
                                  @input="onUrlInput"/>
                    </template>
                    <StartpageAutocomplete v-else-if="type === 'dashboard'"
                                           v-model="identifier"
                                           field="identifier"/>
                    <KCheckbox v-model="linkOpenInNewWindow"
                               field="linkOpenInNewWindow"
                               v-if="type === 'url' && linkUrls.length <= 2"/>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text type="submit">
                      {{ category ? $t("dashboard.actions.add") : $t("dashboard.actions.edit") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </k-field-group>
          </v-dialog>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import Dashboard, { Category, Link } from "@/application/models/dashboard";
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import DashboardSetColumn from "@/components/dashboard/DashboardColumn.vue";
import DashboardSetCategory from "@/components/dashboard/DashboardSetCategory.vue";
import DashboardSetLink from "@/components/dashboard/DashboardSetLink.vue";
import Form from "@/components/vuetifyResource/VuetifyResourceBaseForm.vue";
import draggable from "vuedraggable";
import KCheckbox from "@/components/crud/fields/KCheckbox.vue";
import KSelect from "@/components/crud/fields/KSelect.vue";
import StartpageAutocomplete from "@/components/autocompletes/StartpageAutocomplete.vue";

export default {
  name: "DashboardForm",
  extends: Form,
  components: {
    StartpageAutocomplete,
    KSelect,
    KCheckbox,
    KFieldGroup,
    KTextField,
    DashboardSetColumn,
    DashboardSetLink,
    DashboardSetCategory,
    draggable,
  },
  data() {
    return {
      values: new Dashboard(),

      categoryValidation: null,
      linkValidation: null,
      CATEGORY: "CATEGORY",
      LINK: "LINK",
      isCategoryDragging: false,
      isLinkDragging: false,
      dialog: null,
      column: null,
      categoryEditingObj: null,
      categoryTitle: "",
      category: null,
      linkEditingObj: null,
      linkName: "",
      type: "url",
      linkUrls: [""],
      identifier: "",
      linkOpenInNewWindow: false,
    };
  },
  watch: {
    dialog(value) {
      if (value) return;
      this.column = null;
      this.category = null;
      this.categoryEditingObj = null;
      this.categoryTitle = "";
      this.linkEditingObj = null;
      this.linkName = "";
      this.type = "url";
      this.identifier = "";
      this.type = "url";
      this.linkUrls = [""];
      this.identifier = "";
      this.linkOpenInNewWindow = false;
    },
  },
  methods: {
    addCategory({ colIndex }) {
      this.column = this.values.content?.[colIndex];
      this.openCategoryDialog({ category: new Category() });
    },
    openCategoryDialog({ category }) {
      this.categoryEditingObj = category;
      this.categoryTitle = category.title;
      this.dialog = this.CATEGORY;
    },
    updateCategoryDialog({
      categoryEditingObj,
      categoryTitle,
    }) {
      if (!this.$refs.categoryForm.validate()) return;
      categoryEditingObj.title = categoryTitle;
      if (this.column) this.column.categories.push(categoryEditingObj);
      this.dialog = null;
    },
    deleteCategory({
      colIndex,
      categoryIndex,
    }) {
      this.values.content[colIndex].categories.splice(categoryIndex, 1);
    },
    addLink({
      colIndex,
      categoryIndex,
    }) {
      const column = this.values.content?.[colIndex];
      this.category = column?.categories?.[categoryIndex];
      const link = new Link();
      link.urls = [""];
      this.openLinkDialog({ link });
    },
    openLinkDialog({ link }) {
      this.linkEditingObj = link;
      this.linkName = link.name;
      this.linkUrls = link.urls
      this.type = link.type;
      this.identifier = link.identifier;
      this.linkOpenInNewWindow = link.openInNewWindow;
      this.dialog = this.LINK;
    },
    updateLinkDialog({
      linkEditingObj,
      linkName,
      linkUrls,
      type,
      identifier,
      linkOpenInNewWindow,
    }) {
      if (!this.$refs.linkForm.validate()) return;
      linkEditingObj.name = linkName;
      linkEditingObj.urls = linkUrls;
      linkEditingObj.type = type;
      linkEditingObj.identifier = identifier;
      linkEditingObj.openInNewWindow = linkUrls.length > 1 ? true : linkOpenInNewWindow;
      if (linkEditingObj.urls[linkEditingObj.urls.length - 1] === "") linkEditingObj.urls.pop();
      if (this.category) this.category.links.push(linkEditingObj);
      this.dialog = null;
    },
    deleteLink({
      colIndex,
      categoryIndex,
      linkIndex,
    }) {
      const column = this.values.content?.[colIndex];
      const category = column?.categories?.[categoryIndex];
      category.links.splice(linkIndex, 1);
    },
    onUrlInput() {
      if (this.linkUrls[this.linkUrls.length - 1] === "") return;
      if (this.linkUrls.length >= 5) return;
      this.linkUrls.push("");
    },
  },
};
</script>
