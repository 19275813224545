<template>
  <v-list-item
      :href="href"
      :link="typeof colIndex === 'undefined'"
      :rel="link.openInNewWindow ? 'noreferrer noopener' : ''"
      :target="link.openInNewWindow ? '_blank' : '_self'"
      :to="to"
      class="pr-0"
  >
    <v-list-item-content class="py-0">
      <v-row no-gutters>
        <v-col>
          <v-list-item-title>
            {{ link.name }}
          </v-list-item-title>
        </v-col>
        <v-col v-if="typeof colIndex !== 'undefined'" class="align-self-end" cols="auto">
          <v-btn icon plain small @click="$emit('click:editLink', {link})">
            <v-icon small>$edit</v-icon>
          </v-btn>
          <v-btn icon plain small @click="$emit('click:deleteLink', {colIndex, categoryIndex, linkIndex})">
            <v-icon small>$delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'DashboardSetLink',
  props: {
    link: {
      type: Object,
      required: true,
    },
    // when this is undefined, i think this means that this component is used in the dashboard, if it isn't it's used in a form.
    colIndex: {
      type: Number,
    },
    categoryIndex: {
      type: Number,
    },
    linkIndex: {
      type: Number,
    },
  },
  computed: {
    href() {
      if (this.link.type !== 'url' && this.link.type !== '') return null;
      return typeof this.colIndex === 'undefined' ? this.link.url : null;
    },
    to() {
      if (this.link.type !== 'dashboard') return null;
      return typeof this.colIndex === 'undefined' ? {name: 'dashboard.show', params: {dashboardId: this.link.identifier.id}} : null
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  &.v-list-item {
    min-height: 32px;
  }
}
</style>
