<template>
  <div :class="this.grid" class="k-field-wrapper">
    <v-checkbox v-bind="fieldProps"
                v-on="$listeners"
                :label="translatedLabel"
                :rules="computedRules"
    >
      <template v-for="(_, name) in $slots" v-slot:[name]>
        <slot :name="name"/>
      </template>
    </v-checkbox>
  </div>
</template>

<script>
import Field from '@/components/crud/fields/FieldMixin.vue';

export default {
  name: 'KCheckbox',
  mixins: [Field],
  model: {
    prop: 'inputValue',
    event: 'change',
  },
};
</script>
