<template>
  <KPaginatedAutocomplete :field="field" :paginator="autocomplete" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';
import { autocomplete } from '@/api/endpoints/dashboard';

export default {
  name: 'StartpageAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    autocomplete,
  },
  props: {
    field: {
      type: String,
    },
  },
};
</script>
