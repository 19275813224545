<template>
  <v-col cols="12" sm="6" md="4">
    <v-row v-if="typeof colIndex !== 'undefined'" class="justify-center mb-3">
      <v-col cols="auto">
        <v-btn plain small @click="$emit('click:addCategory', {colIndex})">
          <v-icon small left>$plus</v-icon>
          {{ $t('dashboard.actions.addCategory') }}
        </v-btn>
      </v-col>
    </v-row>
    <slot></slot>
  </v-col>
</template>

<script>
export default {
  name: 'DashboardSetColumn',
  props: {
    colIndex: {
      type: Number,
    },
  },
};
</script>
