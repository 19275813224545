<template>
  <v-card class="mx-0 mb-6" elevation="1">
    <v-card-title>
      <v-row no-gutters class="justify-space-between">
        <v-col cols="auto">{{ category.title }}</v-col>
        <v-col v-if="typeof colIndex !== 'undefined'" class="align-self-center" cols="auto">
          <v-btn plain small icon @click="$emit('click:editCategory', {category})">
            <v-icon small>$edit</v-icon>
          </v-btn>
          <v-btn plain small icon @click="$emit('click:deleteCategory', {colIndex, categoryIndex})">
            <v-icon small>$delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="py-0">
      <v-list>
        <slot></slot>
      </v-list>
    </v-card-text>
    <v-card-actions v-if="typeof colIndex !== 'undefined'">
      <VSpacer/>
      <v-btn plain small @click="$emit('click:addLink', {colIndex, categoryIndex})">
        <v-icon small left>$plus</v-icon>
        {{ $t('dashboard.actions.addLink') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'DashboardSetCategory',
  props: {
    category: {
      type: Object,
      required: true,
    },
    colIndex: {
      type: Number,
    },
    categoryIndex: {
      type: Number,
    },
  },
};
</script>
